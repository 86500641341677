<template>
  <div class="box">
    <div class="u-design">
      <div class="edit-box">
        <div class="edit-shop-content">
          <section class="edit-shop-bottomnav">
            <h3 style="padding: 10px 20px; font-size: 18px; color: #666; border-bottom: 1px solid #eee; margin-bottom: 20px;">订单侧边栏菜单</h3>
            <el-form label-position="right" label-width="100px" size="small">
              <el-form-item label="显示状态" prop="style">
                <el-radio-group v-model="content.style">
                  <el-radio-button :label="0">关闭</el-radio-button>
                  <el-radio-button :label="1">开启</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <draggable v-model="content.data" :options="traggabelOptions" :group="{ name: 'menu', pull: false, put: false }">
              <div class="edit-item" v-for="(item, index) in content.data" :key="item.id">
                <el-form :model="item" label-width="140px" style="position: relative;" size="small">
                <span style="position: absolute; right: 10px; top: -25px; cursor: pointer; z-index: 1">
                  <el-popconfirm
                      title="您确认删除吗？"
                      @confirm="deleteNav(index)">
                    <el-button type="text" slot="reference">
                      <i class="el-icon-delete" style="font-size: 18px;" />
                    </el-button>
                  </el-popconfirm>
                </span>
                  <span style="position: absolute; left: 0px; top: -30px; cursor: pointer; z-index: 1" title="长按拖拽">
                  <el-button type="text" slot="reference">
                      <i class="el-icon-rank" style="font-size: 24px;" />
                    </el-button>
                </span>
                  <el-row style="height: 100px; margin-top: 10px; clear: both;">
                    <el-col :span="6">
                      <SelectImgs :selectNum="1" btnTitle="按钮图标" btnClass="w50" :selectData="[item.image]" :idx="index" :selectFunc="setImgUrl"></SelectImgs>
                    </el-col>
                    <el-col :span="18">
                      <div>
                        <el-row :gutter="10">
                          <el-col :span="4" style="line-height: 35px">
                            按钮名称
                          </el-col>
                          <el-col :span="16">
                            <el-input v-model="item.title" placeholder="请输入最多可输入5个字" maxlength="5" size="small"></el-input>
                          </el-col>
                        </el-row>
                      </div>
                      <div class="mg-tp-20">
                        <el-row :gutter="10">
                          <el-col :span="4" style="line-height: 35px">
                            链接地址
                          </el-col>
                          <el-col :span="16">
                            <select-link :setData="item.nav_link" :index="index" :setFunc="setNavFunc">
                              <template slot="button">
                                <el-input :value="item.nav_link.title" size="small" placeholder="点击设置链接" suffix-icon="el-icon-arrow-right" readonly="readonly"></el-input>
                              </template>
                            </select-link>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </draggable>
            <div style="overflow:hidden; margin-top: 20px; margin-left: 10px;">
              <el-button type="primary" size="small" @click="addNav" class="fl">添加菜单</el-button>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="saveBox">
      <el-button type="primary" size="small" @click="savePage">保存数据</el-button>
    </div>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import selectLink from '../module/select.link.vue'
import draggable from 'vuedraggable';
export default {
  components: {
    selectLink,
    draggable,
    SelectImgs,
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      content: {
        style: 0,
        data: []
      },
      defaultContent: {
        style: 0,
        data: [
          {
            title: '首页', // 导航文字
            image: this.config.SOURCE_DOMAIN + '/images/fw/home.png',
            nav_link: { // 导航链接
              authkey: 'index',
              title: '商城首页',
              path: 'pages/index/index'
            }
          },
          {
            title: '订单', // 导航文字
            image: this.config.SOURCE_DOMAIN + '/images/fw/order.png',
            nav_link: { // 导航链接
              authkey: 'order',
              title: '订单列表',
              path: 'pages/order/index'
            }
          },
          {
            title: '会员', // 导航文字
            image: this.config.SOURCE_DOMAIN + '/images/fw/my.png',
            nav_link: { // 导航链接
              authkey: 'member',
              title: '会员中心',
              path: 'pages/user/index'
            }
          }
        ]
      },
      traggabelOptions: {
        // group: {
        //   name: 'bottomNav',
        //   pull: true,
        //   put: true
        // },
        sort: true,
        animation: 100
      }
    };
  },
  created() {
    this.getInfo()
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    setImgUrl(item, idx) {
      this.content.data[idx].image = item.pic
    },
    setNavFunc(item, idx) {
      this.content.data[idx].nav_link = item
    },
    addNav() {
      this.content.data.push({
        title: '菜单',
        image: '',// this.config.SOURCE_DOMAIN + '/images/fw/collection.png',
        nav_link: {
          type: '',
          name: '',
          id: ''
        }
      })
    },
    deleteNav(index) {
      this.content.data.splice(index,1)
    },
    getInfo() {
      var that = this
      that.showLoading()
      this.$api.shop.designNavInfo({type:5}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          if(res.data) {
            that.content = res.data
          } else {
            that.content = that.defaultContent
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    savePage() {
      var that = this
      that.showLoading()
      var param = {
        type: 5,
        content: this.content
      }
      this.$api.shop.designNavEdit(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.success(res.errmsg)
        } else {
          that.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
.u-design{min-height:578px; display: flex}
.edit-box{margin-bottom: 70px;width: 750px}
.edit-head h3{display:inline-block;margin:0 15px 0 0;font-weight:400;font-size:1pc;line-height:20px}
.edit-shop-bottomnav {
  background-color: #fff;
}
.edit-shop-bottomnav {
  background-color: #fff;
}
.edit-shop-bottomnav .edit-tips{
  float:left;margin-left: 20px; margin-top: 1px;color: #999;
}
.edit-item{
  background-color: #f8f8f9;
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;
}
</style>
